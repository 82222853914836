<template>
  <div class="skill">
    <div class="skill-title"><strong>{{ skill.title }}</strong></div>
    <div class="skill-rating">
      <q-linear-progress :size="lg" :value="skill.percent" color="primary" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Skill",
  props: ["skill"],
  setup() {
    return {};
  },
};
</script>

<style>
.skill {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 10px;
  margin-right: 5rem;
}

.skill > div {
  display: flex;
  padding: 10px;
  align-items: center;
}
</style>
