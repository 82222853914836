<template>
  <q-linear-progress stripe size="5px" :value="0" />
  <div class="chapter">
    <h3 class="text-italic">Skillset</h3>
  </div>
  <Skillset />
</template>

<script>
import Skillset from "@/components/Skillset.vue";

export default {
  name: "Skills",
  components: {
    Skillset,
  },
};
</script>
<style scoped>
h3 {
  font-family: "Raleway", sans-serif;
  color:#0C47A0;
}

.chapter h3 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.chapter h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}
.chapter h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
</style>