<template>
  <div class="chapter">
    <h3 class="text-italic text-bold text-center q-ma-lg up">
      Hi, my name is Matthias Kirsch
    </h3>
    <h4 class="text-italic text-center q-ma-lg">
      <i class="fas fa-code"></i>
      Data Business Analyst
      <i class="fas fa-code"></i>
    </h4>
    <div class="row text-center content">
      <div class="col" >
        <img src="@/assets/cv_profile_lg.jpg" width="100%" />
      </div>
      <div class="col description">
        <p class="text-italic">
          I am passionate about <strong>analyzing data</strong> to <strong>gain insights</strong> and <strong>support business</strong> by developing data-driven solutions.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style scoped>
.description {
  font-size: 24px;
  display: flex;
  margin: auto; 
  padding: 2.5%;
}

.content{
  margin-top: 3%;
}

.up{
  margin-top: 3%; 
}
</style>
