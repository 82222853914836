<template>
  <div class="container">
    <div class="left">
      <Skill
        v-for="skill in technical_skills"
        :key="skill.title"
        :skill="skill"
      />
    </div>
        <div class="left-2">
      <Skill
        v-for="skill in methods_skills"
        :key="skill.title"
        :skill="skill"
      />
    </div>
    <div class="right">
      <Skill
        v-for="skill in business_skills"
        :key="skill.title"
        :skill="skill"
      />
    </div>
  </div>
</template>

<script>
import Skill from "./Skill.vue";

export default {
  name: "Skillset",
  components: {
    Skill,
  },
  setup() {
    const technical_skills = [
      { title: "Python", percent: 0.9 },
      { title: "SQL", percent: 0.95 },
      { title: "Git", percent: 0.9 },
      { title: "Cloud (AWS / Azure)", percent: 0.6 },
    ];
    const methods_skills = [
      { title: "Data Analysis", percent: 0.8 },
      { title: "Data Science", percent: 0.75 },
      { title: "Machine Learning", percent: 0.75 },
      { title: "Data Engineering", percent: 0.6 },
      { title: "Deep Learning", percent: 0.25 },
    ];
    const business_skills = [
      { title: "Requirement Analysis", percent: 0.9 },
      { title: "Agile (Scrum)", percent: 0.9 },
      { title: "Project Management", percent: 0.7 },
      { title: "Leadership", percent: 0.6 },
    ];

    return { technical_skills, business_skills, methods_skills };
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 70px);
  margin-top: 1rem;
}

.left {
  grid-column: 1/3;
  grid-row: 1/4;
  background-color: #f5f5f5;
  border-radius: 25px;
  margin-right: 5%;
}

.left-2 {
  grid-column: 1/3;
  grid-row: 4/9;
  background-color: #f5f5f5;
  border-radius: 25px;
  margin-right: 5%;
  margin-top: 5%;
}

.right {
  grid-column: 3/5;
  grid-row: 1/4;
  background-color: #f5f5f5;
  border-radius: 25px;
}
</style>
