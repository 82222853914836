<template>
  The following timeline gives an overview about my previous projects. I
  included relevant projects from university
  <span class="material-icons"> school </span>, private projects
  <span class="material-icons"> home </span> as well as projects from my jobs
  <span class="material-icons"> work </span>.
  <q-timeline color="secondary">
    <q-timeline-entry
      v-for="project in projects"
      :key="project.title"
      :title="project.title"
      :subtitle="project.time"
      :color="project.color"
      :icon="project.category"
    >
      <div v-if="project.description">
        {{ project.description }}
      </div>
      <div v-for="(role, index) in project.roles" :key="index">
        {{ project.role }}
      </div>
      <div v-if="project.skills">
        <q-badge
          v-for="(skill, index) in project.skills"
          :key="index"
          color="blue-3"
          class="q-ma-sm q-pa-sm"
          >{{ skill }}</q-badge
        >
      </div>
      <div v-if="project.responsibilities">
        <q-badge
          v-for="(responsibility, index) in project.responsibilities"
          :key="index"
          color="red-3"
          class="q-ma-sm q-pa-sm"
          >{{ responsibility }}</q-badge
        >
      </div>
      <img v-if="project.image" :src="project.image" />
      <v-img
        v-else
        :max-height="project.imageHeight ? project.imageHeight : ''"
        :src="project.image"
      />
    </q-timeline-entry>
  </q-timeline>
</template>

<script>
import cat_detector from "../assets/projects/snapshot_cat_20190531_161422.png";

export default {
  name: "ProjectList",
  setup() {
    const projects = [
      {
        time: "2021",
        color: "blue-10",
        category: "work",
        title: "Implementation and Roll-out of a global Business KPI",
        responsibilities: [
          "International Market Roll-out",
          "International Workshops",
          "KPI Implementation",
          "Automotive Sector",
        ],
        roles: ["Business Analyst", "Data Analyst"],
        description:
          "Global initiative to measure the number/share of fully connected end customers. The KPI had to be implemented and adapted to the different markets, business as well as technical requirements. In a set of workshops and technical meetings, the roll-out was planned and implemented. The final step included a migration to AWS to meet additional business requirements. My core responsibilities included coordinating the implementation as Lead Data and Business Analyst as well as gathering and prioritizing user requirements. ",
        skills: ["SQL", "PySpark", "Palantir", "Git", "JIRA", "AWS"],
      },
      {
        time: "2021",
        color: "blue-10",
        category: "work",
        title:
          "Cloud Migration and Reimplementation of Business Logics from a Legacy System (Logistic)",
        responsibilities: [
          "Organizing / Leading Business Workshops",
          "Implementing Business Logics",
          "Automotive Sector",
        ],
        roles: ["Business Analyst, Data Analyst"],
        description:
          "Planning and migration of existing business logics from a legacy system to AWS Redshift. To ensure correct implementations, a test-driven implementation approach was set up. In addition to the technical management of the workshops, I was responsible for the design of the implementation concept and its realization.",
        skills: ["SQL", "AWS", "JIRA"],
      },
      {
        time: "2021",
        color: "blue-10",
        category: "work",
        title:
          "Creation of a Cloud Migration Concept for an On-Premises Solution",
        responsibilities: [
          "Development of a Migration Concept",
          "Requirement Analysis",
          "Organizing / Leading Business Workshops",
          "Automotive Sector",
        ],
        roles: ["Business Analyst, Engagement Manager"],
        description:
          "Based on the identified requirements different cloud-based approaches where evaluated and discussed with business and IT experts. Initial Epics and User Stories where defined to be able to start with the implementation of the the favored alternative solution. As a business analyst, I was responsible for gathering the technical and business requirements. In collaboration with architecture and data processing experts, these requirements were used to define the target image.",
        skills: ["AWS", "JIRA"],
      },
      {
        time: "2020",
        color: "",
        category: "home",
        title: "Project 'Scrum Planning Poker'-Tool",
        description:
          "Implementation of a Scrum Planning Poker application. The idea was to build a variant, where the agile teams (especially when they come from different time zones) can estimate efforts time-independent from each other. Next to the business use case, I wanted to learn how to build a web application based on Vue.js and MongoDB.",
        skills: ["Vue.js", "JavaScript", "MongoDB", "Git", "Zepel"],
        imageHeight: 200,
      },
      {
        time: "2020",
        color: "blue-10",
        category: "work",
        title:
          "Building a Prediction Model to minimize Risks in the Industrialization Process of new Components",
        responsibilities: [
          "Project Management",
          "ML Component",
          "Data Analysis",
          "Organizing / Leading Business Workshops",
          "Requirement Analysis",
          "Automotive Sector",
        ],
        roles: ["Data Scientist", "Engagement Manager"],
        description:
          "Development of an automated ml-based reporting to identify and predict potential risk parts. Data from different data sources / processes is used to improve the model performance and reporting dashboard. I had started as a Data Scientist and after a few months I additionally took over the project management function.",
        skills: [
          "Python",
          "SQL",
          "Git",
          "Hive",
          "AWS",
          "AWS Sagemaker",
          "JIRA",
        ],
      },
      {
        time: "2019",
        color: "",
        category: "home",
        title: "CNN based cat detector",
        description:
          "In this project I wanted to combine my knowledge in AI with a real problem (cat vs. goldfish in our pond). In the process, I wanted to figure out how to set up an end-to-end software architecture and connect it with hardware components (Raspberry PI, USB camera, self-built weatherproof case). The picture shows the first shot of the intruder, which was automatically emailed to me at the time. The moment was indescribable ;-).",
        skills: ["Python", "Bash", "Raspberry PI"],
        image: cat_detector,
        imageHeight: 200,
      },
      {
        time: "2019",
        color: "blue-10",
        category: "work",
        title: "Optimization of Electrode Lifetimes - Predictive Maintenance PoC",
        responsibilities: [
          "Data Exploration",
          "Feature Engineering",
          "Modelling of machine data",
          "Comparison of multiple Classification Models",
          "Automotive Sector",
        ],
        roles: ["Data Scientist"],
        description:
          "Optimization of the electrode service life of crimping systems in electric motor production. Various physical measurement series were analyzed in order to detect changes in electrode states as early as possible.",
        skills: ["Python", "Git", "Jenkins", "Azure Blob Storage"],
      },
      {
        time: "2018",
        color: "blue-10",
        category: "work",
        title: "Automated Evaluation of Offer Documents",
        responsibilities: [
          "Logic Implementation",
          "Dockerization",
          "Automatization",
        ],
        roles: ["Data Scientist", "Data Engineer"],
        description: "Automated analysis of documents with text mining.",
        skills: [
          "Python",
          "Docker",
          "Git",
          "Elastic Stack",
          "Jupyter Notebook",
        ],
      },
      {
        time: "2018",
        color: "blue-10",
        category: "work",
        title:
          "Predictive maintenance in the production of combustion engines and electic motors",
        responsibilities: [
          "Data Exploration",
          "Feature Engineering",
          "Modelling of machine data",
          "Implementation of a Classification Model",
          "Automotive Sector",
        ],
        roles: ["Data Scientist", "Data Engineer"],
        description:
          "Based on various measurement parameters and the use of machine learning, the condition of enignes was modelled. This made it possible to realize a more efficient early detection of previously wrong diagnosed faults.",
        skills: ["Python", "Jupyter Notebook"],
      },
      {
        time: "2017",
        color: "",
        category: "school",
        title: "Retail Analytics",
        responsibilities: [
          "Data Exploration",
          "Data Analysis",
          "Project Management",
        ],
        roles: ["Data Engineer", "Data Analyst"],
        description:
          "Creation of a recommendation system for the fashion retail industry - JMU Würzburg.",
        skills: ["R", "Python", "Jupyter Notebook"],
      },
      {
        time: "2017",
        color: "",
        category: "emoji_events",
        title: "Finalist Audi Smart Factory Hackathon - Ingolstadt",
        responsibilities: [
          "Data Exploration",
          "Data Analysis",
          "Implementation of a Classification Model",
        ],
        roles: ["Data Engineer", "Project Manager"],
        description:
          "Implementation of an classification model to detect defective products.",
        skills: ["Python", "Jupyter Notebook"],
      },
      {
        time: "2014",
        color: "",
        category: "school",
        title: "Video-CMS for HOBOS",
        description:
          "Planing and implementation of a Video-CMS for HOBOS (HOneyBee Online Studies) - JMU Würzburg.",
        skills: ["JavaScript", "PHP"],
      },
    ];

    return { projects };
  },
};
</script>

<style></style>
