<template>
  <q-item
    clickable
    tag="a"
    target="_blank"
    href="https://www.linkedin.com/in/matthias-kirsch-a91a58b5/"
  >
    <q-item-section avatar>
      <q-icon name="fab fa-linkedin" />
    </q-item-section>
    <q-item-section>
      <q-item-label><strong>LinkedIn</strong></q-item-label>
    </q-item-section>
  </q-item>
  <q-item
    clickable
    tag="a"
    target="_blank"
    href="https://www.xing.com/profile/Matthias_Kirsch16/cv"
  >
    <q-item-section avatar>
      <q-icon name="fab fa-xing" />
    </q-item-section>
    <q-item-section>
      <q-item-label><strong>Xing</strong></q-item-label>
    </q-item-section>
  </q-item>
  <q-item tag="a" target="_blank">
    <q-item-section avatar>
      <q-icon color="grey-5" name="fab fa-github" />
    </q-item-section>
    <q-item-section>
      <q-item-label style="color: grey"><strong>Github</strong></q-item-label>
      <q-item-label caption>coming soon</q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
export default {};
</script>

<style></style>
