<template>
  <div class="container">
    <div v-for="certificate in certificates" :key="certificate.badge">
      <Certificate :certificate="certificate" />
    </div>
  </div>
</template>

<script>
import Certificate from "./Certificate.vue";
import badge_aws_practitioner from "../assets/certificates/aws-cloud-practitioner.png"
import badge_az900 from "../assets/certificates/azure-cloud-fundamentals.png"
import badge_pspo1 from "../assets/certificates/pspo-1.svg"
import badge_safe from "../assets/certificates/SA_SAFe5.png"

export default {
  name: "Skillset",
  components: { Certificate },
  setup() {
    const certificates = [
      {
        proof:
          "https://www.credly.com/badges/646616e7-2824-4d93-956d-37abf3b63c48?source=linked_in_profile",
        badge: badge_aws_practitioner,
      },
      {
        proof:
          "https://www.credly.com/badges/bdd6d44d-73e8-41d2-a0a8-9b2467c8efa1?source=linked_in_profile",
        badge: badge_az900,
      },
      {
        proof: "https://www.scrum.org/certificates/437112",
        badge: badge_pspo1,
      },
      {
        proof:
          "https://www.credly.com/badges/90071877-d2f6-4a1f-95e5-ab1bf35e678c?source=linked_in_profile",
        badge: badge_safe,
      },
    ];
    return {
      certificates,
    };
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1rem;
}

.container > div {
  grid-column: 1fr;
  text-align: center;
}
</style>
