<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar class="bg-blue-10 text-whiten">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title class="text-italic">
          <router-link to="/" style="text-decoration: none; color: inherit"
            >MATTHIAS KIRSCH
          </router-link></q-toolbar-title
        >
        <a href="/CV_MatthiasKirsch_DE.pdf" style="text-decoration: none">
          <q-btn outline rounded color="white" label="CV (DE)" />
        </a>
        <a href="/CV_MatthiasKirsch_EN.pdf" style="text-decoration: none; margin-left: 1%">
          <q-btn outline rounded color="white" label="CV (EN)" />
        </a>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="bg-grey-2">
      <q-avatar size="150px" class="profile-image">
        <img src="@/assets/cv_profile_2.jpg" />
      </q-avatar>
      <Certificates />
      <q-list>
        <div class="q-mt-lg">
          <Navbar />
        </div>
        <div class="q-mt-lg">
          <SocialMedia />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container>
      <div class="q-px-lg q-pb-md">
        <router-view></router-view>
      </div>
    </q-page-container>
    <Footer />
  </q-layout>
</template>

<script>
import Certificates from "./components/Certificates.vue";
import Navbar from "./components/Navbar.vue";
import SocialMedia from "./components/SocialMedia.vue";
import Footer from "./components/Footer.vue";
import { ref } from "vue";

export default {
  name: "LayoutDefault",

  components: { Certificates, Navbar, SocialMedia, Footer },

  setup() {
    var leftDrawerOpen = ref(false)

    return {
      leftDrawerOpen
    };
  },
};
</script>

<style scoped>
.profile-image {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
</style>
