<template>
  <q-timeline color="secondary">
    <q-timeline-entry
      v-for="experience in experiences"
      :key="experience.title"
      :title="experience.title"
      :subtitle="experience.time"
      :color="experience.color"
      :icon="experience.icon"
    >
      <strong>{{ experience.loc }}</strong>
      <div v-for="(f, index) in experience.focus" :key="index">{{ f }}</div>
    </q-timeline-entry>
  </q-timeline>
</template>

<script>

export default {
  name: "WorkExperience",
  setup() {
    const experiences = [
      {
        time: "2021 - today",
        title: "Senior Applications Consultant",
        loc: "Capgemini Deutschland GmbH",
        focus: ["Project assignments in leading positions (Engagement Manager, Business Analyst) in the automotive sector.", "Development of data-driven solutions in the areas of Big Data / Cloud / Data Science.", "Deputy People Lead / Counsellor."],
        color: "indigo-10",
        icon: "assignment_ind",
      },
      {
        time: "2019 - 2021",
        title: "Applications Consultant Lead",
        loc: "Capgemini Deutschland GmbH",
        focus: ["Project engagements in the area of Data Engineering / Data Science (production, quality management, cloud migration).", "Sparring partner of the management as a member of the Capgemini Millennial Council Germany."],
      },
      {
        time: "2018 - 2019",
        title: "Applications Consultant",
        loc: "Capgemini Deutschland GmbH",
        focus: ["Project assignments in the field of data science / software engineering (production, autonomous driving, quality management).", "GetStarted program for graduates."],
      },
      {
        time: "2016 - 2017",
        title: "Working Student",
        loc: "COSMO CONSULT BI GmbH",
        focus: [
          "Conception / implementation of customer solutions in the area of predictive analytics (Python) and BI (Qlik Sense).",
          "Development of Qlik Sense / QlikView solutions for the internal business units Operations and Sales as well as for customers.",
          "Preparation of documentation and training materials.",
          "Planning and implementation of a workshop on the topic of business intelligence.",
        ],
      },
      {
        time: "2015 - 2016",
        title: "Student Research Assistant",
        loc: "Lehrstuhl für BWL und Wirtschaftsinformatik - JMU Würzburg",
      },
      {
        time: "2013 - 2016",
        title: "Student Assistant (Online Sales)",
        loc: "AudioLaden Würzburg",
      },
    ];

    return { experiences };
  },
};
</script>

<style></style>
