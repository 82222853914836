<template>
  <q-item>
    <q-item-section avatar>
      <q-icon color="blue-10" name="fas fa-graduation-cap" />
    </q-item-section>
    <q-item-section>
      <q-item-label
        ><router-link to="education" style="text-decoration: none; color: inherit"
          ><strong>Education</strong></router-link
        ></q-item-label
      >
    </q-item-section>
  </q-item>
  <q-item>
    <q-item-section avatar>
      <q-icon color="blue-10" name="fas fa-seedling" />
    </q-item-section>
    <q-item-section>
      <q-item-label
        ><router-link to="projects" style="text-decoration: none; color: inherit"
          ><strong>Projects</strong></router-link
        ></q-item-label
      >
    </q-item-section>
  </q-item>
  <q-item>
    <q-item-section avatar>
      <q-icon color="blue-10" name="fas fa-toolbox" />
    </q-item-section>
    <q-item-section>
      <q-item-label
        ><router-link to="work" style="text-decoration: none; color: inherit"
          ><strong>Work Experience</strong></router-link
        ></q-item-label
      >
    </q-item-section>
  </q-item>
  <q-item>
    <q-item-section avatar>
      <q-icon color="blue-10" name="fas fa-brain" />
    </q-item-section>
    <q-item-section>
      <q-item-label
        ><router-link to="skills" style="text-decoration: none; color: inherit"
          ><strong>Skillset</strong></router-link
        ></q-item-label
      >
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: "Navbar",
  setup() {
    return {};
  },
};
</script>

<style></style>
