<template>
  <q-footer elevated reveal class="glossy" style="height: 30px">
    <q-toolbar class="bg-blue-10 text-whiten" style="height: 30px">
      <q-toolbar-title style="font-size: 10px; color: grey; margin-bottom: 15px"
        >App icon by
        <a style="color: inherit" target="_blank" href="https://icons8.de"
          >Icons8</a
        ></q-toolbar-title
      >
    </q-toolbar>
  </q-footer>
</template>

<script>
export default {};
</script>

<style></style>
