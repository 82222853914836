<template>
    <q-timeline color="secondary">
      <q-timeline-entry
        v-for="education in educations"
        :key="education.title"
        :title="education.title"
        :subtitle="education.time"
      >
        <strong>{{ education.loc }}</strong>
        <div v-if="education.thesis">Thesis: {{ education.thesis }}</div>
        <div v-if="education.focus">Focus: {{ education.focus }}</div>
      </q-timeline-entry>
    </q-timeline>
</template>

<script>
export default {
  name: "Education",
  setup() {
    const educations = [
      {
        time: "2015 - 2017",
        title: "Master's degree, Business Informatics",
        loc: "Julius-Maximilians-Universität Würzburg",
        thesis:
          "Implementation of a context-sensitive and content-based recommender system.",
        focus: "Business Information Systems, Business Analytics",
      },
      {
        time: "2011 - 2015",
        title: "Bachelor's degree, Business Informatics",
        loc: "Julius-Maximilians-Universität Würzburg",
        thesis:
          'Investigation of the possibilities and prerequisites of the customer as a "prosumer" in the field of small and medium-sized service companies, incl. exemplary demonstration.',
      },
      {
        time: "2002 - 2011",
        title: "A-levels",
        loc: "Staatliches Gymnasium Friedberg",
        focus: "Chemistry, Geography",
      },
    ];

    return { educations };
  },
};
</script>

<style></style>
