<template> 
    <a :href="certificate.proof">
      <q-avatar size="50px">
        <!--<img :src="require(`@/assets/img/${certificate.badge}`)" />-->
        <img :src="certificate.badge" />
      </q-avatar>
    </a>
</template>

<script>
export default {
  name: "Certificate",
  props: ["certificate"],
  setup() {
    return {};
  },
};
</script>

<style>
</style>
